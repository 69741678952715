<div class="flex flex-col md:flex-row h-screen">
  <div
    class="w-full md:w-3/5 bg-gradient-to-b from-blue-600 to-blue-900 flex flex-col justify-center items-center min-h-screen md:min-h-0 p-8 md:p-12 relative overflow-hidden">
    <div class="text-center z-10 mb-8 md:mb-0">
      <h1 class="text-white text-3xl md:text-4xl font-bold mb-4">Maxim Impressions</h1>
      <p class="text-white">Welcome to Maxim App Portal</p>
    </div>

    <div class="md:hidden bg-white rounded-lg p-6 shadow-lg w-full max-w-md z-10">
      <h2 class="text-2xl font-bold text-gray-800 mb-2">{{ greeting() }}</h2>
      <p class="text-gray-600 mb-6">{{ greetingMessage() }}</p>
      <form (ngSubmit)="onUserLogin()" class="space-y-4">
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faAt" [ngClass]="isFieldInvalid('username') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input type="text" id="username-mobile"
                 [(ngModel)]="username" name="username-mobile"
                 (blur)="onFieldBlur('username')"
                 [ngClass]="inputClass('username')"
                 class="w-full pl-10 pr-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Username"/>
        </div>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faFingerprint"
                     [ngClass]="isFieldInvalid('password') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input [type]="showPassword() ? 'text' : 'password'" id="password-mobile"
                 [(ngModel)]="password" name="password-mobile"
                 (blur)="onFieldBlur('password')"
                 [ngClass]="inputClass('password')"
                 class="w-full pl-10 pr-10 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Password"/>
          <button type="button" (click)="togglePasswordVisibility()"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showPassword() ? faEyeSlash : faEye" class="text-gray-400"></fa-icon>
          </button>
        </div>
        @if (invalidSubmission()) {
          <div class="text-red-500 text-sm mb-4">{{ errorMessage() }}</div>
        }
        <button
          type="submit"
          [disabled]="isSubmitting()"
          class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center transition duration-200 ease-in-out"
          [ngClass]="{'animate-shake': invalidSubmission()}">
          Login
        </button>
        <div class="text-center">
          <a disabled class="cursor-not-allowed text-gray-500 hover:underline">Forgot Password</a>
        </div>
      </form>
    </div>

    <div class="absolute inset-0 overflow-hidden pointer-events-none">
      <div
        class="absolute w-[100vw] h-[100vw] md:w-[80vw] md:h-[80vw] border border-[#0575E6] rounded-full top-[-50vw] left-[-50vw] md:top-[-20vw] md:left-[-40vw]"></div>
      <div
        class="absolute w-[100vw] h-[100vw] md:w-[80vw] md:h-[80vw] border border-[#0575E6] rounded-full bottom-[-60vw] right-[-50vw] md:bottom-[-40vw] md:right-[-30vw]"></div>
    </div>
  </div>

  <div class="hidden md:flex md:w-2/5 w-full flex-col justify-center p-8 bg-white">
    <div class="w-full max-w-lg mx-auto">
      <h2 class="text-2xl md:text-3xl font-bold text-gray-800 mb-2">{{ greeting() }}</h2>
      <p class="text-gray-600 mb-8">{{ greetingMessage() }}</p>
      <form (ngSubmit)="onUserLogin()" class="space-y-6">
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faAt" [ngClass]="isFieldInvalid('username') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input type="text" id="username-desktop"
                 [(ngModel)]="username" name="username-desktop"
                 (blur)="onFieldBlur('username')"
                 [ngClass]="inputClass('username')"
                 class="w-full pl-10 pr-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Username"/>
        </div>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faFingerprint"
                     [ngClass]="isFieldInvalid('password') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input [type]="showPassword() ? 'text' : 'password'" id="password-desktop"
                 [(ngModel)]="password" name="password-desktop"
                 (blur)="onFieldBlur('password')"
                 [ngClass]="inputClass('password')"
                 class="w-full pl-10 pr-10 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Password"/>
          <button type="button" (click)="togglePasswordVisibility()"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showPassword() ? faEyeSlash : faEye" class="text-gray-400"></fa-icon>
          </button>
        </div>
        @if (invalidSubmission()) {
          <div class="text-red-500 text-sm mb-4">{{ errorMessage() }}</div>
        }
        <button
          type="submit"
          [disabled]="isSubmitting()"
          class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center transition duration-200 ease-in-out"
          [ngClass]="{'animate-shake': invalidSubmission()}">
          Login
        </button>
        <div class="text-center">
          <a disabled class="cursor-not-allowed text-gray-500 hover:underline">Forgot Password</a>
        </div>
      </form>
    </div>
  </div>
</div>
